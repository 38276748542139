import type { DiskoEnabledJs, ShortcutCreateJs } from "@/generated/models";
import type { DiskoType } from "@/services/models/filesystem";
import type { RequestOptions } from "../common/server";
import { EmptyGuid } from "@/common/lib";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export interface CreateModel {
  FolderId?: string;
  FilesystemId?: string;
  Name?: string;
  Type: DiskoType;
}
export async function create({ folderId, filesystemId, name, type }: { folderId?: string; filesystemId?: string; name?: string; type: DiskoType }) {
  const model: CreateModel = {
    FolderId: folderId,
    FilesystemId: filesystemId,
    Name: name,
    Type: type,
  };
  const formData = objectToFormData(model);
  const response = await server.post("disko/create", formData);
  return response.data as ShortcutCreateJs;
}

export async function enabledForOrganization(options: { organizationId: string }, { signal }: RequestOptions = {}) {
  if (!options.organizationId || options.organizationId === EmptyGuid) return { enabled: false, simsEnabled: false } as DiskoEnabledJs;

  const params = {
    organizationId: options.organizationId,
  };
  const response = await server.get("disko/enabledfororganization", { params, signal });
  return response.data as DiskoEnabledJs;
}
