<script setup lang="ts">
import { isElectron, isElectronRenderer } from "@/common/environment";
import { cspNonce } from "@/csp";
import { ConfigProvider } from "radix-vue";
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import { useChunkPreloadErrorHandling } from "./chunk-reload";
import { useProvideRecaptchaContext } from "./components/recaptcha/useRecaptchaContext";
import { SimpleToaster } from "./components/simple-toast";
import { SimpleTooltipProvider } from "./components/simple-tooltip";
import Banners from "./features/banner/Banners.vue";
import ClickthroughUploadProgress from "./features/clickthrough/ClickthroughUploadProgress.vue";
import CommandK from "./features/command-k/CommandK.vue";
import GlobalHubs from "./features/hubs/GlobalHubs.vue";
import { useTimezoneAutoDetection } from "./features/settings/useTimezones";
import { useAuth } from "./features/user/useAuth";
import { useAuthGuard } from "./router/auth-guard";
import { useConfigureRoutes } from "./router/useConfigureRoutes";
import TitleBar from "./TitleBar.vue";
import "./style.css";

const DesktopAppFeatures = defineAsyncComponent(() => import("./features/desktop-app/DesktopAppFeatures.vue"));
const AxureCloudDevTools = defineAsyncComponent(() => import("./features/dev-tools/AxureCloudDevTools.vue"));

useProvideRecaptchaContext();
useChunkPreloadErrorHandling();
useTimezoneAutoDetection();
useConfigureRoutes();
useAuthGuard();

const { user } = useAuth();

// before we fully provide mobile version
// use this workaround by allowing routes to define meta file to set min-width
const route = useRoute();
const useMinWidthLayout = computed(() => {
  return !!route.meta.useMinWidth;
});

const allowDevTools = computed(() => {
  if (import.meta.env.DEV) return true;
  if (!user.value) return false;
  const email = user.value.userEmail;
  return email.endsWith("@axure.com") || email.endsWith("@axurecorp.com");
});
</script>

<template>
  <ConfigProvider :nonce="cspNonce">
    <SimpleTooltipProvider>
      <div
        class="flex w-full flex-col h-full"
        :class="{
          'min-w-5xl': useMinWidthLayout,
        }"
      >
        <TitleBar v-if="isElectron" />
        <Banners />
        <RouterView />
      </div>
      <SimpleToaster />
      <CommandK v-if="user" />
      <ClickthroughUploadProgress v-if="user" />
      <DesktopAppFeatures v-if="isElectronRenderer" />
      <GlobalHubs v-if="user" />
      <AxureCloudDevTools v-if="allowDevTools" />
    </SimpleTooltipProvider>
  </ConfigProvider>
</template>
