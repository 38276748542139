export const workspaceParameterName = "workspaceId";
export const folderParameterName = "folderId";
export const organizationParameterName = "organizationId";
export const redirectParameterName = "redirect";
export const shortcutParameterName = "shortcut";
export const inviteParameterName = "invite";
export const newItemParameterName = "new";
export const screenParameterName = "screen";
export const objectParameterName = "object";
export const documentNodeParameterName = "node";
export const issueCodeParameterName = "issueCode";
export const commentParameterName = "comment";
export const domainPrefixParameterName = "domainPrefix";
export const actionParamName = "a";
export const encryptedPayloadParamName = "p";
export const tokenParamName = "token";
export const modeParamName = "mode";
export const targetParamName = "target";

export const routeNames = {
  home: "home",
  signIn: "signIn",
  joinAxure: "joinAxure",
  resetPassword: "resetPassword",
  resetPasswordExpired: "resetPasswordExpired",
  manage: "manage",
  recents: "recents",
  workspace: "workspace",
  fileOverview: "fileOverview",
  fileComments: "fileComments",
  fileHistory: "fileHistory",
  filePreview: "filePreview",
  fileInspect: "fileInspect",
  fileBuild: "fileBuild",
  fileShow: "fileShow",
  fileNotShared: "fileNotShared",
  fileDeleted: "fileDeleted",
  settings: "settings",
  settingsProfile: "settingsProfile",
  settingsNotifications: "settingsNotifications",
  settingsOrganization: "settingsOrganization",
  settingsOrganizationGeneral: "settingsOrganizationGeneral",
  settingsOrganizationMembers: "settingsOrganizationMembers",
  settingsOrganizationAuthentication: "settingsOrganizationAuthentication",
  settingsOrganizationAuthenticationSso: "settingsOrganizationAuthenticationSso",
  enterprise: "enterprise",
  enterpriseCreate: "enterpriseCreate",
  enterpriseRegister: "enterpriseRegister",
  onPremSetup: "onPremSetup",
  onPremSetupDatabaseConnection: "onPremSetupDatabaseConnection",
  onPremSetupDatabaseConnectionError: "onPremSetupDatabaseConnectionError",
  onPremSetupDatabaseSetup: "onPremSetupDatabaseSetup",
  onPremSetupDatabaseUpgrade: "onPremSetupDatabaseUpgrade",
  onPremSetupDatabaseProgress: "onPremSetupDatabaseProgress",
  onPremSetupDatabaseUser: "onPremSetupDatabaseUser",
  onPremSetupAdminUser: "onPremSetupAdminUser",
  onPremSetupLicense: "onPremSetupLicense",
  notFound: "notFound",
  inviteFromRP: "inviteFromRP",
} as const;
