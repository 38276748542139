//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum OperationType {
  Add = 0,
  Edit = 1,
  Delete = 2
}
export const enum PackageType {
  Page = 0,
  Component = 1,
  Property = 2
}
export const enum SsoId {
  Axure = 0,
  AxShare = 1,
  Portal = 2,
  Disko = 3
}
export const enum RpErrorTypes {
  None = 0,
  UnKnown = 1,
  AxShareError = 2,
  AxShareException = 3,
  AuthenticationFailed = 4,
  TokenExpired = 5,
  LoginLockedOut = 6,
  InReadonlyMode = 7,
  NoPermissionToAccess = 8,
  CreateShortCutParentDeleted = 9,
  ShortcutNotExist = 10,
  ShortcutHasNoRepository = 11,
  ShortcutDeleted = 12,
  CannotFindVersionFile = 13,
  EntryForShortcutNotFound = 14,
  CannotFindFileKeyFor = 15,
  RepoTranTaken = 16,
  LockFileFailed = 17,
  UnlockFileFailed = 18,
  CommitFailed = 19,
  CommitFilesOutDated = 20,
  FilesCheckedOutByOthers = 21,
  FilesDeleted = 22,
  HaveConflicts = 23,
  ExportPathReadOnly = 24,
  OpenFileReadOnly = 25,
  ServerNoResponse = 26,
  ConnectionErrorOffline = 27,
  SVNCannotFindTeamDirectory = 28,
  InvalidReturnValue = 29,
  WorkingCopyLocked = 30,
  TeamDirectoryNotFound = 31,
  CleanUpBackUpFileNotCreated = 32,
  CleanUpFileLocked = 33,
  NotATeamProject = 34,
  LocalFolderAccessError = 35,
  AccountNotFound = 36,
  ShortcutTypeMissMatch = 37,
  ShortcutGenerationFailed = 38,
  ShortcutStillGenerating = 39,
  FileTooNew = 40,
  EnterpriseRequired = 41,
  CannotCreateUser = 42,
  PreventNewUsers = 43,
  UserPasswordTooShort = 44,
  TrailingSpaceInPassword = 45,
  EmptyPassword = 46,
  EmailIsInvalid = 47,
  EmailAlreadyInUse = 48,
  SaveFromPreuploadFailed = 49,
  TeamProjectDowngraded = 50,
  InvalidPasswordComplexity = 60,
  InvalidUser = 61,
  PasswordForSuperAdmin = 62,
  PasswordForOtherAdmins = 63,
  ShouldNotPublishToTeam = 100,
  SamlRedirectToIdp = 600,
  SamlUserMismatched = 601,
  SamlCannotResetPassword = 602,
  LdapRequiresClearPassword = 700,
  AseExpired = 800,
  NotUsingEnterprise = 810,
  RedemptionKeyNotFound = 900,
  LicenseAuditFailed = 901,
  FilesystemAccessError = 950
}
export const enum ApiErrorTypes {
  SignInReadOnlyError = 1,
  ReadOnlyError = 2
}
export const enum WorkspacePermissionType {
  ViewOnly = 0,
  CanEdit = 1
}
export const enum DiscussionAction {
  Create = 0,
  Resolve = 1,
  Unresolve = 2
}
export const enum DiscussionType {
  Issue = 0,
  Comment = 1
}
export const enum ShortcutAction {
  Create = 0,
  Edit = 1,
  Rename = 2,
  Moved = 3,
  Clone = 4,
  Deleted = 5
}
export const enum WorkspaceAction {
  Created = 0,
  Joined = 1,
  Left = 2,
  Archived = 3,
  Unarchived = 4,
  Migrated = 5,
  Renamed = 6
}
export const enum FolderAction {
  Moved = 0
}
export const enum DiskoMentionAction {
  Add = 0
}
export const enum DiskoMentionType {
  Document = 0,
  DiagramObject = 1
}
export interface ActivityObjectHelpers
{
}
export interface ActivityFeedListingJs
{
  FeedItems: ActivityFeedItem[];
  Profiles: { [key:string]: UserProfileJs };
  FilesystemInfo: ActivityFeedFilesystemInfo;
  Shortcuts: { [key:string]: ActivityFeedShortcutJs };
}
export interface ActivityFeedFilesystemInfo
{
  Name: string;
  FilesystemId: string;
  OwnerId: string;
  OwnerName: string;
  IsDefaultFs: boolean;
  IsArchived: boolean;
  IsFavorite: boolean;
}
export interface ActivityFeedShortcutJs
{
  Shortcut: string;
  Name: string;
  ShortcutState: string;
  ShortcutType: number;
}
export interface ActivityFeedItem
{
  FilesystemId: string;
  Timestamp: string;
  Shortcut: string;
  Type: number;
  Data: EventItem;
}
export interface EventItem
{
  UserId: string;
  UserName: string;
}
export interface DiscussionsItem extends EventItem
{
  Action: DiscussionAction;
  Type: DiscussionType;
  IssueCode: string;
  IssueDescription: string;
  IssueDescriptionRichText: string;
  IssueCommentText: string;
  IssueCommentTextRichText: string;
  IssueCreator: string;
  IssueCreatorId?: string;
  ShortcutName: string;
  PageName: string;
  PageFileName: string;
  PageId: string;
  ShortPageId: string;
  MentionedUsers: string[];
  Images: string[];
}
export interface ShortcutItem extends EventItem
{
  Action: ShortcutAction;
  TargetPrototypeId?: string;
  PublishMessage: string;
  Changeset?: Changeset;
  MentionedUsers?: string[];
  ShortcutName: string;
  Current: string;
  Previous: string;
  CurrentFilesystemId?: string;
  CurrentFolderId?: string;
  CurrentFilesystemName: string;
  CurrentFolderName: string;
  PreviousFilesystemId?: string;
  PreviousFolderId?: string;
  PreviousFilesystemName: string;
  PreviousFolderName: string;
}
export interface WorkspaceItem extends EventItem
{
  Action: WorkspaceAction;
  Current: string;
  Previous: string;
}
export interface FolderItem extends EventItem
{
  Action: FolderAction;
  FolderId: string;
  FolderName: string;
  CurrentFilesystemId?: string;
  CurrentFolderId?: string;
  CurrentFilesystemName: string;
  CurrentFolderName: string;
  PreviousFilesystemId?: string;
  PreviousFolderId?: string;
  PreviousFilesystemName: string;
  PreviousFolderName: string;
}
export interface DiskoMentionItem extends EventItem
{
  Action: DiskoMentionAction;
  Type: DiskoMentionType;
  ShortcutName: string;
  SourceId: string;
  Content: string;
  MentionedUsers: string[];
}
export interface AxShareConfigModel
{
  AxShareClientUrl: string;
  AxSharePublicClientUrl: string;
  AxShareHost: string;
  AxShareHostUrl: string;
  AxShareHostSecureUrl: string;
  AxSitesPrototypeDomain: string;
  AxSitesFramedPrototypeDomain: string;
  AccountServiceUrl: string;
  AccountServiceSecureUrl: string;
  LicensingServiceHost: string;
  MatchProtocolMode: boolean;
  AxShareOnPrem: boolean;
  NeedsSetup: boolean;
  NeedsDbUpgrade: boolean;
  DisableSetup: boolean;
  LdapAuthenticationEnabled: boolean;
  Version: string;
  ReleaseDate: string;
  SchemaVersion: string;
  IsSubInstance: boolean;
  SubInstanceId: string;
  VCurrentUrl: string;
  MaxFileSize: number;
  SubInstancesSupported: boolean;
  SubInstanceTopLevelDomain: string;
  SamlUrl: string;
  EnablePlugins: boolean;
  EnableBetaFeatures: boolean;
  PasswordUseExtraRules: boolean;
  ManagePageMessage: string;
  ReadOnlyMode: boolean;
  ReadOnlyMessage: string;
  GoogleReCaptchaSiteKey: string;
  EnablePushNotifications: boolean;
  EnableWorkspacesMigration: boolean;
  EnableActivityFeedUI: boolean;
  AllowOndemand: boolean;
  AllowProfiling: boolean;
  EnableProfiling: boolean;
}
export interface AxShareCurrentUserInfo
{
  UserId: string;
  AccessLevel: UserType;
  SubInstanceProviderExists: boolean;
  AxureRpRole: UserAxureRpRole;
  CloudRole: UserCloudRole;
}
export interface AxShareIdentityProvider
{
  Id: string;
  SubInstanceId: string;
  Certificate: string;
  SignOnUrl: string;
  LogOutUrl: string;
  CertMeta: string;
  LogOnLink: string;
  SamlLdapConflictDetected: boolean;
  AllowCreateNewAccountsAutomatically: boolean;
}
export interface AxShareSubInstanceInfo
{
  Level: SubInstanceLevel;
  Expiration?: string;
  IsExpired: boolean;
  TrialDaysLeft: number;
  MaxAdminAuthors: number;
  IsGracePeriod: boolean;
  GracePeriodDaysLeft: number;
  OwnerEmail: string;
  MemberAllowGuestCreation: boolean;
  GuestAllowGuestCreation: boolean;
  Name: string;
  SeatUsersCount: number;
  IdentityProviderExists: boolean;
}
export interface SubInstanceOptions
{
  MemberAllowGuestCreation: boolean;
  GuestAllowGuestCreation: boolean;
}
export interface Changeset
{
  Entries: ChangesetEntry[];
  Message: string;
}
export interface ChangesetEntry
{
  PackageId: string;
  PackageType: PackageType;
  ShortId: string;
  PackageName: string;
  OperationType: OperationType;
}
export interface Constants
{
  AccountServerErrorMessage: string;
}
export interface EmailSettings
{
  EmailsEnabled: boolean;
  EmailSender: string;
  EmailSenderAddress: string;
  SmtpSsl: boolean;
  SmtpHost: string;
  SmtpPort?: number;
  SmtpLogin: string;
  SmtpPassword: string;
  TraceEnabled: boolean;
  SmtpConfigured: boolean;
}
export interface FbEnabledJs
{
  feedbackEnabled: boolean;
  passwordProtected: boolean;
  viewCookieName: string;
  userCookieName: string;
  isSubInstance: boolean;
}
export interface FeedbackPollingInfoJs
{
  success: boolean;
  hasChanges: boolean;
  unreadIssueMentionsCount: number;
  unreadCommentMentionsCount: number;
  originalPollTime: string;
  lastPollTime: string;
  continuePolling: boolean;
  message: string;
}
export interface GetIssueCommentJs
{
  comment: IssueCommentJs;
  pageId: string;
  profile: { [key:string]: UserProfileJs };
  mostRecentCommentTimestamp?: string;
}
export interface DeleteIssueJs
{
  issue: DeletedIssueCode;
  mostRecentCommentTimestamp?: string;
}
export interface DeletedIssueCode
{
  code: string;
}
export interface DeleteCommentJs
{
  issue: IssueJs;
  comment: DeletedIssueCommentId;
  profiles: { [key:string]: UserProfileJs };
  mostRecentCommentTimestamp?: string;
}
export interface DeletedIssueCommentId
{
  id: string;
}
export interface GetIssueJs
{
  issue: IssueJs;
  pageId: string;
  profile: { [key:string]: UserProfileJs };
  mostRecentCommentTimestamp?: string;
}
export interface IssueListJs
{
  issues: IssueJs[];
  profiles: { [key:string]: UserProfileJs };
  lastPollTime: string;
  mostRecentCommentTimestamp: string;
  hasCommentsOnDeletedPages: boolean;
}
export interface IssueByShortcutListJs
{
  data: IssueByShortcutListItemsJs;
  success: boolean;
  readOnlyMode: boolean;
  readOnlyMessage: string;
  feedbackEnabled: boolean;
}
export interface IssueByShortcutListItemsJs
{
  issues: IssueJs[];
  profiles: { [key:string]: UserProfileJs };
  totalIssueCount: number;
}
export interface IssueCommentListJs
{
  comments: IssueCommentJs[];
  profiles: { [key:string]: UserProfileJs };
  isUnresolved: boolean;
}
export interface IssueJs
{
  id: string;
  code: string;
  shortPageId: string;
  pageId: string;
  pageName: string;
  fileName: string;
  description: string;
  isRichText?: boolean;
  userId?: string;
  creator: string;
  createdDate: string;
  modifier: string;
  modifiedDate?: string;
  changedDate: string;
  comments: IssueCommentJs[];
  numComments: number;
  lastIssueComment: IssueCommentJs;
  screenshotIds: string[];
  screenshotUrls: string[];
  fullsizeUrls: string[];
  x?: number;
  y?: number;
  relativeX?: number;
  relativeY?: number;
  color: string;
  pinNumber?: number;
  resolvedDate?: string;
  isUnread: boolean;
  hasUnreadMention: boolean;
}
export interface IssueCommentJs
{
  id: string;
  issueCode: string;
  userId?: string;
  commenter: string;
  commentText: string;
  isRichText?: boolean;
  commentDate: string;
  commentModifiedDate?: string;
  screenshotIds: string[];
  screenshotUrls: string[];
  fullsizeUrls: string[];
  hasUnreadMention: boolean;
}
export interface ImagePreUploadJs
{
  success: boolean;
  id: string;
}
export interface FrequentlyUsedEmojisJs
{
  LastUsed: string[];
}
export interface GetLicenseKey
{
  success: boolean;
  licenseKey: string;
  licenseJs: LicenseJs;
}
export interface IdentityProviderResponse extends ResponseObject
{
  Id: string;
  Name: string;
  SubInstanceId: string;
  SignOnUrl: string;
  CertThumbprint: string;
  Exclusive: boolean;
  LogOutUrl: string;
  CurrentAxShareHostSecureUrl: string;
}
export interface LdapSettings
{
  AuthenticationProvider: AuthenticationProviderType;
  ActiveDirectoryServerName: string;
  ActiveDirectoryDomainName: string;
  AllowActiveDirectoryCreateNewAccountsAutomatically: boolean;
  ActiveDirectoryAdminUser: string;
  ActiveDirectoryAdminPassword: string;
  LdapHost: string;
  LdapUserTemplate: string;
  LdapUserFilter: string;
  LdapUserEmailAttribute: string;
  LdapUseSSL: boolean;
  LdapCertificate: string;
  LdapAdminUser: string;
  LdapAdminPassword: string;
  LdapCertMeta: string;
  AllowLdapCreateNewAccountsAutomatically: boolean;
  LdapForgotPasswordLink: string;
  SamlLdapConflictDetected: boolean;
}
export interface MigrateFromCloudToAcbResult
{
  UserActivationRequired: boolean;
  SystemAdmins: SsoResponse[];
}
export interface UsersForMigrate
{
  AlreadyMigrated: SsoResponse[];
  CanBeMigrated: SsoResponse[];
}
export interface NewUserInviteData
{
  userEmail: string;
  workspaceId: string;
  workspaceName: string;
  organizationName: string;
}
export interface ProjectPage
{
  Id: string;
  OriginalId: string;
  Name: string;
  FailedToGenerate: boolean;
  Thumbnails: ResponsiveImage[];
}
export interface ShortcutPagesResponse
{
  Shortcut: ShortcutJs;
  Pages: ProjectPage[];
}
export interface ExtendedProjectPage extends ProjectPage
{
  Url: string;
}
export interface PrototypeViewerParams
{
  Params: { [key:string]: string };
}
export interface MoveFilesystemItemsRequest
{
  FilesystemId: string;
  To: string;
  Folders: string[];
  Shortcuts: string[];
}
export interface MoveFilesystemItemsResponse
{
  FromFilesystemId: string;
  ToFilesystemId: string;
  ToFilesystemName: string;
  ToFolderId: string;
  ToFolderName: string;
  ToFilesystemRootFolder: boolean;
}
export interface DeleteFilesystemItemsRequest
{
  FilesystemId: string;
  Folders: string[];
  Shortcuts: string[];
}
export interface ResponseObject
{
  success: boolean;
  message: string;
  errorMessage: string;
  redirecturl: string;
  forceRedirect?: boolean;
}
export interface ResponseObjectOf<T> extends ResponseObject
{
  data: T;
}
export interface CreateMasterJs
{
  Master: Master;
}
export interface ValidateRequestJs
{
  IsValid: boolean;
}
export interface FilesystemRootListingJs
{
  FilesystemInfo: FilesystemJs;
  Listing: ListingJs;
}
export interface VarsJs
{
  Vars: { [key:string]: string };
}
export interface ListingJs
{
  Invitations: InvitationJs[];
  Filesystems: FilesystemJs[];
  FilesystemsToJoin: FilesystemJs[];
  Folders: FolderRowJs[];
  Shortcuts: ShortcutJs[];
  AdditionalVars: { [key:string]: string };
}
export interface InvitationJs
{
  FilesystemId: string;
  Owner: string;
  Owner2: SsoResponse;
  Sender: SsoResponse;
  FilesystemName: string;
  Message: string;
  CreatedOn: string;
  SubInstanceId: string;
  IsArchived: boolean;
}
export interface FilesystemJs
{
  DisplayName: string;
  IsDefaultFs: boolean;
  FilesystemId: string;
  IsOwner: boolean;
  OwnerId: string;
  Owner: string;
  OwnerSso: SsoResponse;
  InvitedUsers: string[];
  SharedUsers: string[];
  InvitedUsers2: SharedFilesystemMappingJs[];
  SharedUsers2: SharedFilesystemMappingJs[];
  ViewerFs: boolean;
  HttpsOnly: boolean;
  UserViewOnly: boolean;
  CreatedOn: string;
  IsArchived: boolean;
  IsFavorite: boolean;
  SubInstanceId: string;
  DefaultMappingType: FilesystemDefaultMappingType;
  OrganizationName: string;
  IsFilesystemToJoin?: boolean;
  NotificationState: FilesystemNotificationStateJs;
}
export interface FilesystemNotificationStateJs
{
  FilesystemId: string;
  HasNewComments: boolean;
  UnreadMentionsCount: number;
}
export interface SharedFilesystemMappingJs
{
  user: SsoResponse;
  isViewer: boolean;
  isAdmin?: boolean;
  filesystemId?: string;
}
export interface FolderRowJs
{
  FolderId: string;
  FolderName: string;
  CreatedOn: string;
  NotificationState: FolderNotificationStateJs;
  ModifiedOn: FolderModifiedOnJs;
}
export interface FolderModifiedOnJs
{
  Value: string;
  Shortcut: string;
  Username: string;
}
export interface FolderNotificationStateJs
{
  HasNewComments: boolean;
  TotalCommentsCount: number;
  NewCommentsCount: number;
  NewCommentShortcut: string;
  NewCommentShortcutFeedbackEnabled: boolean;
  UnreadMentionsCount: { [key:string]: number };
}
export interface ShortcutJs
{
  PrototypeUrl: string;
  CustomDomain: boolean;
  Shortcut: string;
  Name: string;
  CreatedOn: string;
  ModifiedOn?: string;
  ModifiedBy: string;
  DeletedOn?: string;
  HasPassword: boolean;
  FeedbackEnabled: boolean;
  IsUserOnly: boolean;
  ShortcutState: string;
  TargetPrototypeId?: string;
  TargetPrototypeFileName: string;
  TargetPrototypeCreatedOn?: string;
  TargetPrototypeGenerationFailedTimestamp?: string;
  TargetPrototypeGenerationTimestamp?: string;
  Regenerating?: boolean;
  InFsViewerFolder: boolean;
  ResourceDirVersion?: number;
  IsTeamProject: boolean;
  IsExpoProject: boolean;
  ShortcutType: number;
  IsUserOnlyWorkspace: boolean;
  WorkspaceName: string;
  FailedPagesCount: number;
  AllowOndemand: boolean;
  OnDemandEnabled: boolean;
  GenType: number;
  AccessCode: string;
  NotificationState: ShortcutNotificationStateJs;
}
export interface OrganizationJs
{
  Id: string;
  Name: string;
  CurrentUserAccessLevel: UserType;
}
export interface OrganizationWithTreeNodesJs extends OrganizationJs
{
  Workspaces: TreeNodeWorkspaceJs;
}
export interface WorkspaceUserPermissions
{
  User: SsoResponse;
  Permission: WorkspacePermissionType;
  IsOwner: boolean;
  IsAdmin: boolean;
  IsInvitationPending: boolean;
  CanBeRemoved: boolean;
  CanChangePermission: boolean;
}
export interface WorkspaceOrganizationPermissions
{
  Organization: OrganizationJs;
  DefaultMappingType: FilesystemDefaultMappingType;
  CanMappingTypeBeChanged: boolean;
  AllowGuestCreation: boolean;
}
export interface WorkspaceSecurityJs
{
  Members: WorkspaceUserPermissions[];
  OrganizationPermissions: WorkspaceOrganizationPermissions;
}
export interface CreateProjectTreeJs
{
  PrivateWorkspace: TreeNodeJs;
  WorkspacesInOrganization: OrganizationWithTreeNodesJs[];
  WorkspacesToJoinInOrganization: OrganizationWithTreeNodesJs[];
}
export interface ShortcutCloneJs
{
  Shortcut: string;
  Name: string;
  ShortcutType: number;
  Vars: { [key:string]: string };
}
export interface ShortcutTypeJs
{
  Shortcut: string;
  ShortcutType: number;
}
export interface ShortcutCreateJs
{
  Shortcut: ShortcutJs;
  Vars: { [key:string]: string };
}
export interface ShortcutNotificationStateJs
{
  HasNewComments: boolean;
  NewCommentsCount: number;
  CurrentCommentPageId?: string;
  CurrentIssueCode: string;
  TotalCommentsCount: number;
  UnreadMentionsCount: { [key:string]: number };
}
export interface CommentsChangedArgs
{
  UserId?: string;
  FilesystemId: string;
  Shortcut: string;
  IssueCode: string;
  PageId?: string;
  Delta: number;
  LatestTimestamp?: string;
}
export interface WorkspaceDeletedArgs
{
  FilesystemId: string;
}
export interface WorkspaceInvitationDeletedArgs
{
  FilesystemId: string;
  FilesystemDelete?: boolean;
}
export interface WorkspaceRenamedArgs
{
  FilesystemId: string;
  NewName: string;
}
export interface WorkspaceLeaveArgs
{
  FilesystemId: string;
  UserId: string;
}
export interface ShortcutRedirectCommentInfoJs
{
  ShortcutType: number;
  IssueCode: string;
  PageId?: string;
  ShortPageId: string;
}
export interface RecentJs
{
  Shortcut: ShortcutJs;
  Date: string;
  Path: string;
  OrganizationId: string;
  FilesystemId: string;
  FolderId: string;
}
export interface ListJs<T>
{
  Items: T[];
}
export interface DomainJs
{
  Owner: string;
  Id: string;
  Domain: string;
  Verified: boolean;
  Level: number;
  ShortcutName: string;
  ShortcutId: string;
}
export interface SeoJs
{
  Id: string;
  FileName: string;
  PageName: string;
  PageTitle: string;
  PrettyUrl?: string;
  MetaDescription: string;
}
export interface HistoryListingJs
{
  TotalRevisionCount: number;
  Revisions: HistoryJs[];
  Profiles: { [key:string]: UserProfileJs };
}
export interface HistoryJs
{
  Revision: number;
  User: SsoResponse;
  Notes: string;
  Changeset?: Changeset;
  NotesRichText: RichText;
  CreatedOn: string;
  NewMajorVersion?: number;
  OldMajorVersion?: number;
}
export interface RedirectJs
{
  Id: string;
  Type: number;
  From: string;
  To: string;
}
export interface WorkspaceUserRowJs
{
  Id: string;
  Email: string;
  CreatedOn: string;
  Level: number;
  ReviewOnly: boolean;
}
export interface AccountRowJs
{
  Id: string;
  Email: string;
  Nickname: string;
  CreatedOn: string;
  Level: UserType;
  IsSaml: boolean;
  IsDeactivated: boolean;
  IsRpEnterprise: boolean;
  TrialDaysLeft: number;
  IsValidatedRpEnterprise: boolean;
  AxureRpRole: UserAxureRpRole;
  CloudRole: UserCloudRole;
  ProfileImageUrl: string;
  JoinedOn?: string;
  SubInstanceId: string;
}
export interface NotificationJs
{
  Id: string;
  Name: string;
  ProjectName: string;
  WorkspaceName: string;
  TargetId?: string;
  TargetType: EntryType;
  ShortcutType: number;
  ShortcutKey: string;
  Method: NotificationMethod;
  SubRate: number;
  SlackChannel: string;
  MsTeamsUrl: string;
  Type: number;
  PrototypeUrl: string;
}
export interface NotificationSettingJs
{
  NotificationType: NotificationMethod;
  ProjectCommentsType: ProjectCommentsMethod;
}
export interface NotificationMuteItemJs
{
  Id: string;
  NotificationType: NotificationMethod;
  TargetId?: string;
  TargetType: ItemType;
  ProjectName: string;
  WorkspaceName: string;
}
export interface ZippedFileJs
{
  FileUrl: string;
}
export interface LicenseJs
{
  regName: string;
  key: string;
  aseUserCount: number;
  term: number;
  expireDate?: string;
  autoRenew: boolean;
  isTrial: boolean;
}
export interface TrialJs
{
  ExpireDate: string;
  TrialKey: string;
}
export interface LicenseInfoResponse
{
  BestLicense: LicenseJs;
  CurrentTrial: TrialJs;
}
export interface SsoResponseOptions
{
  IncludeProfile: boolean;
  IncludeSubInstanceInfo: boolean;
  IncludeIdentityProviderInfo?: boolean;
}
export interface SsoResponse extends ResponseObject
{
  userId: string;
  userEmail: string;
  verified: boolean;
  pending: boolean;
  serverVersion: string;
  authToken: string;
  verifyAccountUrl: string;
  nickname: string;
  bio: string;
  profileImageUrl: string;
  modifiedOn: string;
  isRpEnterprise: boolean;
  isWhitelisted: boolean;
  isReviewer: boolean;
  meta: { [key:string]: string };
  welcomeEmailSent: boolean;
  localTimeZone: string;
  trialDaysLeft: number;
  isTrialActive: boolean;
  axureRpRole: number;
  userType: number;
}
export interface ExtendedSsoResponse extends SsoResponse
{
  IdentityProviderId?: string;
  IsIdpAdmin: boolean;
  IdpSessionId: string;
  AllowGuestCreation: boolean;
}
export interface VersionInfoResponse
{
  BuildVersion: string;
  EnterpriseVersion: string;
}
export interface RequestInviteResponse
{
  WorkspaceName: string;
}
export interface RpResponseObject extends ResponseObject
{
  Payload: any;
  ErrorType: RpErrorTypes;
  AuthToken: string;
  ExceptionId?: string;
  DeletedOn?: string;
  UserEmail: string;
  UserId: string;
}
export interface RpResponseObjectOf<T> extends RpResponseObject
{
  Payload: T;
}
export interface AseLicenseResponseObject extends ResponseObject
{
  License: LicenseJs;
  OwnerEmail: string;
}
export interface ExtensionMethods
{
}
export interface FilesystemFolderInfo
{
  RootFolderId: string;
  FilesystemInfo: FilesystemJs;
}
export interface FilesystemSearchItemJs
{
  id: string;
  folderId: string;
  workspaceId: string;
  name: string;
  type: string;
  subtype?: number;
  path: string;
  updated: string;
  lastUpdatedShortcut: string;
  lastUpdatedBy: string;
  viewOnly: boolean;
  isUserOnly?: boolean;
  prototypeUrl: string;
  shortcutState: string;
  hasPassword?: boolean;
  IsUserOnlyWorkspace?: boolean;
  ResourceDirVersion?: number;
}
export interface FilesystemSearchDataJs
{
  Filesystems: SearchFilesystemJs[];
  Shortcuts: SearchShortcutJs[];
  Folders: SearchFolderRowJs[];
}
export interface SearchFilesystemJs
{
  Path: string;
  Filesystem: FilesystemJs;
}
export interface SearchShortcutJs
{
  IsArchived: boolean;
  Path: string;
  Shortcut: ShortcutJs;
}
export interface SearchFolderRowJs
{
  IsViewer: boolean;
  IsArchived: boolean;
  Path: string;
  Folder: FolderRowJs;
  FilesystemId: string;
}
export interface ApiErrorData
{
  ApiErrorType: ApiErrorTypes;
}
export interface NotificationEventArgs
{
  Id: string;
}
export interface ProjectChangedArgs extends NotificationEventArgs
{
  Shortcut: string;
  FilesystemId?: string;
  FolderId?: string;
  UserId?: string;
}
export interface ProjectMovedArgs extends NotificationEventArgs
{
  Shortcut: string;
}
export interface ProjectDeletedArgs extends NotificationEventArgs
{
  Shortcut: string;
}
export interface CreateFolderArgs extends NotificationEventArgs
{
  Folder: FolderRowJs;
  ParentFolder: FolderRowJs;
  FilesystemId: string;
}
export interface RenameFolderArgs extends NotificationEventArgs
{
  FolderId: string;
  FolderName: string;
  FilesystemId: string;
}
export interface UpdateAccessLevelArgs extends NotificationEventArgs
{
  UserId: string;
  FilesystemId: string;
}
export interface CreateProjectArgs extends NotificationEventArgs
{
  FilesystemId: string;
  FolderId: string;
  UserId: string;
}
export interface MoveOrDeleteItemsArgs extends NotificationEventArgs
{
  FilesystemId: string;
  FolderId?: string;
  Shortcuts: string[];
  Folders: string[];
  UserId: string;
  DestinationFolderId?: string;
}
export interface MoveFilesystemItemsArgs extends NotificationEventArgs
{
  FilesystemId: string;
  Shortcuts: string[];
  Folders: string[];
  UserId: string;
  DestinationFolderId: string;
  DestinationFilesystemId: string;
  OriginFoldersIds: string[];
}
export interface DeleteFilesystemItemsArgs extends NotificationEventArgs
{
  FilesystemId: string;
  Shortcuts: string[];
  Folders: string[];
  UserId: string;
  OriginFoldersIds: string[];
}
export interface LeaveOrDeleteUsersFromOrganizationArgs extends NotificationEventArgs
{
  UsersIds: string[];
  SubInstanceId: string;
  NewOwnerId?: string;
}
export interface DeleteOrganizationArgs extends NotificationEventArgs
{
  SubInstanceId: string;
}
export interface RenameOrganizationArgs extends NotificationEventArgs
{
  SubInstanceId: string;
  NewName: string;
}
export interface WorkspaceInvitedArgs extends NotificationEventArgs
{
  FilesystemId: string;
  InvitedByMention: boolean;
  OwnerName: string;
  OwnerEmail: string;
  OwnerProfileImage: string;
  MentionComment: string;
}
export interface UserMentionedArgs extends NotificationEventArgs
{
  Shortcut: string;
  PageId: string;
  SourceId: string;
  Message: string;
  AuthorEmail: string;
  AuthorName: string;
  AuthorProfileImage: string;
  MentionType: string;
}
export interface WorkspaceActivityEventArgs extends NotificationEventArgs
{
  FilesystemId: string;
}
export interface ResetPasswordValidateJs
{
  Email: string;
}
export interface UserProfileJs
{
  userId: string;
  userEmail: string;
  username: string;
  profileImg: string;
}
export interface ShareStatusJs
{
  success: boolean;
  readOnlyMode: boolean;
  readOnlyMessage: string;
  isSubInstance: boolean;
}
export interface TreeNodeJs
{
  name: string;
  id: string;
}
export interface TreeNodeFileJs extends TreeNodeJs
{
  filetype: string;
  rpmajorversion: string;
}
export interface TreeNodeWorkspaceJs extends TreeNodeJs
{
  owner: SsoResponse;
  defaultWorkspace: boolean;
  isFavorite: boolean;
  isFolder: boolean;
  explicitlyJoined: boolean;
  children: TreeNodeJs[];
  inviteOnly: boolean;
}
export interface CloudLibrariesJs
{
  defaultLibraries: CloudLibraryJs[];
}
export interface CloudLibraryJs
{
  shortcut: string;
  id: string;
  name: string;
  fileType: string;
}
export interface TimeZoneJs
{
  Id: string;
  DisplayName: string;
  baseUtcOffsetMinutes: number;
}
export interface FolderJs
{
  FolderId: string;
  Path: string;
  FilesystemId: string;
}
export interface DiskoEnabledJs
{
  enabled: boolean;
  simsEnabled: boolean;
}
export interface FolderNavItemJs
{
  id: string;
  name: string;
  isFilesystem: boolean;
  subInstanceId?: string;
  fsRootFolderId?: string;
  isDefaultFs: boolean;
  isOwner: boolean;
}
export interface FolderNavJs
{
  breadcrumbJson: FolderNavItemJs[];
}
export interface ShortcutPluginPageMappingJs
{
  Id: string;
  PageName: string;
}
export interface ShortcutPluginMappingJs
{
  Id: string;
  PluginName: string;
  Location: PluginLocation;
  LocationElementLabel?: string;
  Content: string;
  Priority?: number;
  InsertInAllPages: boolean;
  taggedPages: ShortcutPluginPageMappingJs[];
}
export interface ShortcutPluginPageInfoJs
{
  Id: string;
  PageName: string;
}
export interface ShortcutPluginInfo
{
  ShortcutPluginMappings: ShortcutPluginMappingJs[];
  AllPages: ShortcutPluginPageInfoJs[];
}
export interface SetAxureRpRoleResponse
{
  seatsAssignedUsers: string[];
}
export interface SetupProgressDetails
{
  Progress: number;
  Log: string;
  Operation: string;
}
export interface DatabaseConnection
{
  Dialect: string;
  Database: string;
  Hostname: string;
  Port?: number;
  User: string;
  Password: string;
}
export interface ResponsiveImage
{
  Url: string;
  Density: number;
}
export interface RichTextNodeTypes
{
  Doc: string;
  Paragraph: string;
  Text: string;
  Mention: string;
  Emoji: string;
  QuotedText: string;
}
export interface RichTextNodeTypesAttributes
{
}
export interface RichTextNodeAttributes
{
  id: string;
  label: string;
}
export interface RichTextNode
{
  type: string;
  text: string;
  content: RichTextNode[];
  attrs: { [key:string]: string };
}
export interface RichText extends RichTextNode
{
}
export interface RichTextComment
{
  Content: RichText;
}
export interface SetLicenseKey extends ResponseObject
{
  IsTrial: boolean;
}
export interface UserSuggestions
{
  Shortcut: string;
  FilesystemId: string;
  Suggestions: UserSuggestion[];
}
export interface UserSuggestion
{
  UserId: string;
  IsMe: boolean;
  UserEmail: string;
  UserName: string;
  UserProfileImgUrl: string;
  UserBio: string;
  JoinedFilesystem: boolean;
}
export interface ContentType
{
  Json: string;
  WwwFormUrlEncoded: string;
}
export interface Mention
{
  Id: string;
  Label: string;
}
export interface Emoji
{
  Id: string;
  Native: string;
  ShortName: string;
}
export interface QuotedText
{
  Text: string;
}
export const enum FileType {
  Jpg = 0,
  Png = 1,
  Gif = 2,
  Axvg = 3
}
export interface Interaction
{
  Type: InteractionType;
  Animation: Animation;
}
export interface Action extends IdObject
{
  Name: string;
  Hotspot: Hotspot;
  Type: ActionType;
  Setting: ActionSetting;
  ParentId: string;
  IsMaster: boolean;
  RelativeToBottom?: number;
}
export interface NavigationAction extends ActionSetting
{
  TargetArtboardId: string;
}
export interface ScrollPositionAction extends ActionSetting
{
  Location: Point;
  IsSmoothScroll: boolean;
}
export interface ExternalUrlAction extends ActionSetting
{
  Url: string;
}
export interface ActionSetting
{
  Interaction: Interaction;
  Type: ActionType;
}
export interface ActionSettingConverter
{
}
export interface DebugConverter
{
}
export interface Animation
{
  Out: AnimationSetting;
  In: AnimationSetting;
}
export interface AnimationSetting
{
  Easing: EasingType;
  Animation: AnimationType;
  Duration: number;
}
export interface ArtboardInfo
{
  ArtboardId: string;
  ActionPoolId: string;
  CurrRevision: number;
  Name: string;
  IsModified: boolean;
  IsArchived: boolean;
  IsDeleted: boolean;
}
export interface ActionPool extends IdObject
{
  ArtboardId: string;
  Actions: { [key:string]: Action };
}
export interface Artboard extends IdObject
{
  S3_BUCKET: string;
  AssetId: string;
  SubAssetIds: string[];
  AssetInfo: { [key:string]: Asset };
  MasterIds: string[];
  Name: string;
  NextArtboardId: string;
  HeaderHeight: number;
  FooterHeight: number;
  HasHeader: boolean;
  HasFooter: boolean;
  IsArchived: boolean;
  IsHandoffCapable: boolean;
  Asset: Asset;
  SubAssets: { [key:string]: Asset };
  ActionPool: ActionPool;
  CurrRevision: number;
}
export interface AssetInfo
{
  AssetId: string;
  IsModified: boolean;
  IsDeleted: boolean;
}
export interface ArtboardAssetManifest
{
  ArtboardAsset: Asset;
  SubAssets: Asset[];
}
export interface Asset extends IdObject
{
  OriginalFileName: string;
  FileName: string;
  ThumbFileName: string;
  FilePath: string;
  Type: FileType;
  Dimensions: Dimensions;
  LayerId: string;
  Scale: number;
}
export interface Hotspot
{
  Start: Point;
  End: Point;
  Dimensions: Dimensions;
}
export interface Point
{
  X: number;
  Y: number;
}
export interface IdObject
{
  Id: string;
  CreatedOn: string;
  ModifiedOn?: string;
  DeletedOn?: string;
  IsModified: boolean;
  IsDeleted: boolean;
}
export interface SyncManifest
{
  Shortcut: string;
  Artboards: SyncArtboard[];
}
export interface SyncArtboard
{
  Id: string;
  Name: string;
  Asset: SyncAsset;
  SubAssets: SyncAsset[];
}
export interface SyncAsset
{
  LayerId: string;
  Name: string;
  FileName: string;
  Scale: number;
}
export interface Master extends IdObject
{
  Name: string;
  Actions: { [key:string]: Action };
}
export interface Project extends IdObject
{
  ShortcutKey: string;
  ProjectName: string;
  Platform: Platform;
  MasterIds: string[];
  ArtboardIds: string[];
  ArtboardInfoMap: { [key:string]: ArtboardInfo };
  Zoom: number;
}
export interface ExpoRevision
{
  ArtboardId: string;
  CurrentRevisionNumber: number;
  RevisionTimestamp: { [key:number]: string };
  ModifiedOn?: string;
  CreatedOn: string;
  IsModified: boolean;
}
export interface ExpoJson
{
}
export interface ExpoStaticContent
{
  project: Project;
  masters: { [key:string]: Master };
  artboards: { [key:string]: Artboard };
  assets: { [key:string]: Asset };
  images: { [key:string]: string };
  thumbnails: { [key:string]: string };
  bgImgLink: string;
}
export interface AddHotspotRequest
{
  tag: string;
  artboardId?: string;
  masterId?: string;
  hotspot: Hotspot;
  type: ActionType;
  setting: ActionSetting;
  relativeToBottom?: number;
}
export interface ArchiveArtboardRequest
{
  artboardId: string;
}
export interface CreateMasterRequest
{
  name: string;
  artboardId: string;
}
export interface DeleteArtboardRequest
{
  artboardId: string;
}
export interface DeleteArtboardsRequest
{
  artboardIds: string[];
}
export interface DeleteHotspotRequest
{
  isMaster: boolean;
  sourceId: string;
  actionId: string;
}
export interface EditHotspotRequest
{
  sourceId: string;
  targetId: string;
  actionId: string;
  hotspot: Hotspot;
  type: ActionType;
  setting: ActionSetting;
  relativeToBottom?: number;
  isSourceMaster: boolean;
  isTargetMaster: boolean;
}
export interface ModifyMasterRequest
{
  artboardId: string;
  masterId: string;
  name: string;
}
export interface ReorderArtboardRequest
{
  targetAbId: string;
  tailAbId: string;
  fullResequence: boolean;
}
export interface SetHeaderFooterHeightRequest
{
  artboardId: string;
  height: number;
  isHeader: boolean;
}
export interface SetZoomRequest
{
  zoom: number;
}
export interface UpdatePlatformRequest
{
  platform: Platform;
}
export interface HotspotAddResponse
{
  tagVal: string;
  action: Action;
}
export interface HotspotEditResponse
{
  actionId: string;
  action: Action;
}
export interface HotspotDeleteResponse
{
  isMaster: boolean;
  sourceId: string;
  actionId: string;
}
export const enum ActionType {
  SetPanelState = 0,
  PreviousScreen = 1,
  NextScreen = 2,
  PreviousHistoryScreen = 3,
  PointOnScreen = 4,
  ExternalUrl = 5
}
export const enum InteractionType {
  Click = 0,
  DoubleClick = 1,
  SwipeRight = 2,
  SwipeLeft = 3,
  SwipeUp = 4,
  SwipeDown = 5
}
export const enum AnimationType {
  None = 0,
  Swing = 1,
  Linear = 2,
  EaseInCubic = 3,
  EaseOutCubic = 4,
  EaseInOutCubic = 5,
  Bounce = 6,
  Elastic = 7
}
export const enum EasingType {
  None = 0,
  Fade = 1,
  PushRight = 2,
  PushLeft = 3,
  SlideRight = 4,
  SlideLeft = 5,
  SlideUp = 6,
  SlideDown = 7,
  FlipRight = 8,
  FlipLeft = 9,
  FlipUp = 10,
  FlipDown = 11
}
export const enum DeviceType {
  iPhone8 = 0,
  iPhone8Plus = 1,
  iPhoneSE = 2,
  iPhone13Mini = 3,
  iPadMini8_3 = 10,
  iPadPro11 = 12,
  iPadPro12_9 = 13,
  AndroidSmall = 17,
  Custom = 20,
  Web = 21,
  Auto = 22,
  AndroidLarge = 31,
  SurfacePro8 = 32,
  iPhone13And14 = 33,
  iPhone14Plus = 34,
  iPhone14And15Pro = 35,
  iPhone14And15ProMax = 36
}
export const enum ForegroundColor {
  Light = 0,
  Dark = 1
}
export const enum PlatformType {
  Web = 0,
  Mobile = 1,
  MobileApple = 2
}
export const enum Orientation {
  Portrait = 0,
  Landscape = 1
}
export const enum PageAlign {
  Left = 0,
  Center = 1
}
export const enum HorizontalAlign {
  Left = 0,
  Center = 1,
  Right = 2
}
export const enum VerticalAlign {
  Top = 0,
  Middle = 1,
  Bottom = 2
}
export const enum ImageRepeat {
  NoRepeat = 0,
  Repeat = 1,
  RepeatX = 2,
  RepeatY = 3,
  StretchToCover = 4,
  StretchToContain = 5,
  StretchHorizontalAndVertical = 6
}
export interface MobilePlatform extends Platform
{
  HasStatusBar: boolean;
  StatusBarHeight: number;
  ForegroundColor: ForegroundColor;
  ShowScreensBelowStatusBar: boolean;
}
export interface PlatformInfo
{
}
export interface PlatformConverter
{
}
export interface Dimensions
{
  Width: number;
  Height: number;
}
export interface PlatformSettings
{
  Orientation: Orientation;
  Dimensions: Dimensions;
}
export interface Platform
{
  Type: PlatformType;
  Device: DeviceType;
  Settings: PlatformSettings;
  BackgroundColor: string;
  IsUsingBackgroundColor: boolean;
}
export interface WebPlatform extends Platform
{
  PageAlignment: PageAlign;
  HasBackgroundImage: boolean;
  BackgroundImageExt: string;
  BackgroundImageName: string;
  BackgroundImageWidth: number;
  BackgroundImageHeight: number;
  HorizontalAlignment: HorizontalAlign;
  VerticalAlignment: VerticalAlign;
  ImageRepeat: ImageRepeat;
}
export interface DeviceDropDownOption
{
  Value: number;
  Text: string;
  DeviceName: string;
  Device: DeviceType;
  Dimensions?: Dimensions;
}
export enum UserAxureRpRole {
  Viewer = 0,
  Publisher = 1
}
export enum UserCloudRole {
  Viewer = 0,
  Author = 1
}
export enum UserType {
  Regular = 0,
  SubInstanceSuperAdmin = 1,
  SubInstanceAdmin = 2,
  SubInstanceMember = 3,
  SubInstanceGuest = 4,
  SubInstanceTechnicalAdmin = 5
}
export enum FilesystemDefaultMappingType {
  InviteOnly = 0,
  ViewOnly = 1,
  CanEdit = 2
}
export enum PluginLocation {
  Head = 0,
  Body = 1,
  Element = 2
}
export enum SubInstanceLevel {
  Permanent = 0,
  Trial = 1,
  Subscribed = 2
}
export enum NotificationMethod {
  Email = 0,
  Slack = 1,
  MsTeams = 3
}
export enum EntryType {
  All = 0,
  Filesystem = 1,
  Shortcut = 2
}
export enum ProjectCommentsMethod {
  All = 0,
  Some = 1,
  None = 2
}
export enum ItemType {
  Workspace = 0,
  Project = 1
}
export enum AuthenticationProviderType {
  BuiltIn = 0,
  ActiveDirectory = 1,
  Ldap = 2
}
