import { useQueryClient } from "@tanstack/vue-query";
import { queries } from "ls/state/queries";
import { type RouteRecordRaw, useRouter } from "vue-router";
import { routeNames } from "../../router/constants";
import { useOnPremSetupContext } from "./context";

export const onPremSetupRoute: RouteRecordRaw = {
  path: "/setup",
  name: routeNames.onPremSetup,
  component: () => import("ls/features/onprem-setup/OnPremSetup.vue"),
  meta: {
    requiresAuth: false,
    onPremSetup: true,
  },
  async beforeEnter(to) {
    const { connection } = useOnPremSetupContext();

    const queryClient = useQueryClient();
    const config = await queryClient.ensureQueryData(queries.config());
    if (config.NeedsDbUpgrade) {
      if (to.name !== routeNames.onPremSetupDatabaseUpgrade) {
        return {
          name: routeNames.onPremSetupDatabaseUpgrade,
        };
      }
    } else if (!connection.value && to.name !== routeNames.onPremSetupDatabaseConnection) {
      return {
        name: routeNames.onPremSetupDatabaseConnection,
      };
    }

    return true;
  },
  children: [
    {
      path: "database-connection",
      name: routeNames.onPremSetupDatabaseConnection,
      component: () => import("ls/features/onprem-setup/DatabaseConnection.vue"),
    },
    {
      path: "database-connection-error",
      name: routeNames.onPremSetupDatabaseConnectionError,
      component: () => import("ls/features/onprem-setup/DatabaseConnectionError.vue"),
    },
    {
      path: "database-upgrade",
      name: routeNames.onPremSetupDatabaseUpgrade,
      component: () => import("ls/features/onprem-setup/DatabaseUpgrade.vue"),
    },
    {
      path: "database-setup",
      name: routeNames.onPremSetupDatabaseSetup,
      component: () => import("ls/features/onprem-setup/DatabaseSetup.vue"),
    },
    {
      path: "database-progress",
      name: routeNames.onPremSetupDatabaseProgress,
      component: () => import("ls/features/onprem-setup/DatabaseProgress.vue"),
    },
    {
      path: "database-user",
      name: routeNames.onPremSetupDatabaseUser,
      component: () => import("ls/features/onprem-setup/DatabaseUser.vue"),
    },
    {
      path: "administrator-user",
      name: routeNames.onPremSetupAdminUser,
      component: () => import("ls/features/onprem-setup/OnPremAdminUser.vue"),
    },
    {
      path: "setup-license",
      name: routeNames.onPremSetupLicense,
      component: () => import("ls/features/onprem-setup/OnPremSetupLicense.vue"),
    },
  ],
};

function useOnPremSetupGuard() {
  const router = useRouter();
  const queryClient = useQueryClient();

  router.beforeEach(async to => {
    const config = await queryClient.ensureQueryData(queries.config());
    if (!config.AxShareOnPrem) {
      return true;
    }

    const setupOnly = config.NeedsSetup || config.NeedsDbUpgrade;
    if (!setupOnly) {
      return true;
    }

    return to.meta.onPremSetup || { name: routeNames.onPremSetup };
  });
}

function useConfigureOnPremSetupRoutes() {
  const router = useRouter();
  const queryClient = useQueryClient();

  router.beforeEach(async to => {
    const config = await queryClient.ensureQueryData(queries.config());
    if (!config.AxShareOnPrem) {
      return true;
    }

    if (config.NeedsSetup || config.NeedsDbUpgrade) {
      if (!router.hasRoute(routeNames.onPremSetup)) {
        router.addRoute(onPremSetupRoute);
        return to.fullPath;
      }
    }

    return true;
  });
}

export function useConfigureOnPremRoutes() {
  useConfigureOnPremSetupRoutes();
  useOnPremSetupGuard();
}
