import type { ListJs } from "@/common/models";
import type { AccountRowJs, OrganizationJs, ResponseObject, SharedFilesystemMappingJs, UserCloudRole, UserType } from "@/generated/models";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export async function getAccounts({ organizationId }: { organizationId: string }, { signal }: RequestOptions = {}) {
  const response = await server.get("organization/getAccounts", {
    params: {
      organizationId,
    },
    signal,
  });
  return response.data as ListJs<AccountRowJs>;
}

export async function getOrganization({ organizationId }: { organizationId: string }, { signal }: RequestOptions = {}) {
  const response = await server.get("organization/getUserOrganization", {
    params: {
      organizationId,
    },
    signal,
  });
  return response.data as OrganizationJs;
}

export async function getOrganizations({ signal }: RequestOptions = {}) {
  const response = await server.get("organization/getUserOrganizations", {
    signal,
  });
  return response.data as OrganizationJs[];
}

interface OrganizationCreateModel {
  Name: string;
}
export async function createOrganization({ name }: { name: string }) {
  const model: OrganizationCreateModel = {
    Name: name,
  };
  const formData = objectToFormData(model);
  const response = await server.post("organization/createOrganization", formData);
  return response.data as OrganizationJs;
}

export async function getFilesystemMappings({ organizationId }: { organizationId: string }, { signal }: RequestOptions = {}) {
  const response = await server.get("organization/getFilesystemMappingsForOrganization", {
    params: {
      organizationId,
    },
    signal,
  });
  return response.data as SharedFilesystemMappingJs[];
}

interface OrganizationLeaveModel {
  organizationId: string;
  newOwner?: string;
}
export async function leaveOrganization(options: { organizationId: string; newOwner?: string }) {
  const model: OrganizationLeaveModel = {
    organizationId: options.organizationId,
    newOwner: options.newOwner,
  };
  const formData = objectToFormData(model);
  const response = await server.post("organization/leaveOrganization", formData);
  return response.data as OrganizationJs;
}

export interface RemoveUsersFromOrganizationModel {
  usersToRemoveIds: string;
  organizationId: string;
}

export async function removeUsersFromOrganization(options: { userIds: string[]; organizationId: string }) {
  const model: RemoveUsersFromOrganizationModel = {
    usersToRemoveIds: options.userIds.join(","),
    organizationId: options.organizationId,
  };

  const formData = objectToFormData(model);
  const response = await server.post("organization/removeUsersFromOrganization", formData);
  return response.data as ResponseObject;
}

export interface InviteUsersToOrganizationModel {
  userEmails: string;
  organizationId: string;
  organizationRole: number;
  cloudRole: number;
}

export async function inviteUsersToOrganization(options: { organizationId: string; userEmails: string[]; organizationRole: UserType; cloudRole: UserCloudRole }) {
  const model: InviteUsersToOrganizationModel = {
    userEmails: options.userEmails.join(","),
    organizationId: options.organizationId,
    organizationRole: options.organizationRole,
    cloudRole: options.cloudRole,
  };

  const formData = objectToFormData(model);
  const response = await server.post("organization/inviteUsersToOrganization", formData);
  return response.data as ResponseObject;
}

export interface RenameOrganizationModel {
  organizationId: string;
  name: string;
}

export async function renameOrganization(options: { organizationId: string; name: string }) {
  const model: RenameOrganizationModel = {
    organizationId: options.organizationId,
    name: options.name,
  };

  const formData = objectToFormData(model);
  const response = await server.post("organization/renameOrganization", formData);
  return response.data as ResponseObject;
}

interface OrganizationDeleteModel {
  organizationId: string;
}
export async function deleteOrganization({ organizationId }: { organizationId: string }) {
  const model: OrganizationDeleteModel = {
    organizationId,
  };
  const formData = objectToFormData(model);
  const response = await server.post("organization/deleteOrganization", formData);
  return response.data as OrganizationJs;
}

export interface SetUserCloudRoleModel {
  organizationId: string;
  userIds: string;
  cloudRole: number;
}

export async function setCloudRole(options: { organizationId: string; userIds: string[]; cloudRole: UserCloudRole }) {
  const model: SetUserCloudRoleModel = {
    organizationId: options.organizationId,
    userIds: options.userIds.join(","),
    cloudRole: options.cloudRole,
  };

  const formData = objectToFormData(model);
  const response = await server.post("organization/setCloudRole", formData);
  return response.data as ResponseObject;
}
