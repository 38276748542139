<script setup lang="ts">
import type { ShortcutJs } from "@/generated/models";
import { ShortcutType } from "@/services/models/filesystem";
import {
  ClickthroughSm,
  DocSm,
  LibRpSm,
  RpSm,
  SimSm,
  TeamLibRpSm,
  TeamRpSm,
  WhiteboardSm,
} from "ls/components/icons";
import { FileIcon } from "lucide-vue-next";
import { computed } from "vue";
import {
  isClickthroughProject,
  isDocumentProject,
  isRpProject,
  isSimProject,
  isWhiteboardProject
} from "./utils";

const props = defineProps<{
  file: ShortcutJs;
}>();

const icon = computed(() => {
  if (isRpProject(props.file)) {
    const type = props.file.ShortcutType as keyof typeof rpIcons;
    return rpIcons[type] || RpSm;
  }
  if (isClickthroughProject(props.file)) return ClickthroughSm;
  if (isDocumentProject(props.file)) return DocSm;
  if (isWhiteboardProject(props.file)) return WhiteboardSm;
  if (isSimProject(props.file)) return SimSm;
  return FileIcon;
});
</script>

<script lang="ts">
const rpIcons = {
  [ShortcutType.Rp]: RpSm,
  [ShortcutType.Lib]: LibRpSm,
  [ShortcutType.TeamRp]: TeamRpSm,
  [ShortcutType.TeamLib]: TeamLibRpSm,
} as const;
</script>

<template>
  <component :is="icon" aria-hidden="true" />
</template>
