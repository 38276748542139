<script setup lang="ts">
import type { Toast } from "./useToast";
import { isFunction, isString } from "@/common/general";
import { ToastAction, ToastClose, ToastDescription, ToastTitle } from "radix-vue";
import { computed } from "vue";

const props = defineProps<{
  toast: Toast;
}>();

const variant = computed(() => props.toast.options.variant ?? "primary");
</script>

<template>
  <div
    :class="{
      'bg-zinc-800 text-white ring-zinc-600': variant === 'primary',
      'bg-red-950 text-red-100 ring-red-900': variant === 'destructive',
    }"
    class="grid grid-cols-[1fr_auto] grid-rows-2 gap-px overflow-hidden rounded-sm shadow-sm ring-1"
  >
    <div
      class="row-span-2 max-w-md content-center items-center px-3 py-2.5 ring-1"
      :class="{
        'ring-zinc-600': variant === 'primary',
        'ring-red-900': variant === 'destructive',
      }"
    >
      <ToastTitle
        v-if="toast.options.title"
        class="select-text break-anywhere"
      >
        {{ toast.options.title }}
      </ToastTitle>
      <ToastDescription v-if="toast.options.description" class="mt-1 select-text break-anywhere">
        <template v-if="isString(toast.options.description)">
          {{ toast.options.description }}
        </template>
        <component
          :is="toast.options.description"
          v-else-if="isFunction(toast.options.description)"
        />
      </ToastDescription>
    </div>
    <ToastAction
      v-if="toast.options.action"
      :alt-text="toast.options.actionAltText"
      as-child
      class="flex flex-1 items-center justify-center px-3 py-2.5 text-blue-300 ring-1"
      :class="{
        'ring-zinc-600 hover:bg-zinc-600': !variant || variant === 'primary',
        'ring-red-900 hover:bg-red-900': variant === 'destructive',
      }"
    >
      <component
        :is="toast.options.action"
        v-if="isFunction(toast.options.action)"
      />
      <RouterLink
        v-else-if="toast.options.action.to"
        :to="toast.options.action.to"
      >
        {{ toast.options.action.text }}
      </RouterLink>
      <button
        v-else
        type="button"
        @click="toast.options.action.onClick"
      >
        {{ toast.options.action.text }}
      </button>
    </ToastAction>
    <ToastClose
      class="flex flex-1 items-center justify-center p-3 py-2.5 ring-1"
      :class="{
        'row-span-2': !toast.options.action,
        'ring-zinc-600 hover:bg-zinc-600': !variant || variant === 'primary',
        'ring-red-900 hover:bg-red-900': variant === 'destructive',
      }"
    >
      Dismiss
    </ToastClose>
  </div>
</template>
